* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

body,
html,
#root {
  background: #181716;
  color: #fffcfa;
  font-family: "acumin-pro-wide";
  width: 100vw;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
}

.App {
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 90vh;
  width: 100%;
}

.top {
  display: flex;
  flex-direction: column;
}

.heading {
  font-style: normal;
  font-weight: 500;
  font-size: 12.5vw;
  line-height: 95%;
}

.subheading {
  font-style: normal;
  font-weight: 500;
  font-size: 54px;
  line-height: 65px;
}

.video-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: all 0.3s;
  opacity: 0;
  background: #181716;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.center-container {
  display: flex;
  align-items: flex-start;
}

.video-wrap.active {
  opacity: 1;
  pointer-events: all;
}

.video {
  width: 80vw;
}

.close-button {
  width: 40px;
  cursor: pointer;
  margin-left: 20px;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.video-link {
  font-weight: 500;
  font-size: 46px;
  text-decoration: underline;
  cursor: pointer;
}

@media (min-width: 769px) and (max-width: 1199px) {
  .logo {
    width: 240px;
  }

  .heading {
    font-size: 12vw;
  }

  .subheading {
    font-size: 40px;
  }

  .video-link {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .logo {
    width: 140px;
  }

  .heading {
    font-size: 11.5vw;
  }

  .subheading {
    font-size: 35px;
  }

  footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .video-link {
    margin-bottom: 40px;
  }
}

@media (max-width: 480px) {
  .App {
    padding: 80px 5%;
  }
  .center-container {
    height: 100%;
    position: relative;
    align-items: center;
  
  }
  .video {
    width: 100%;
  }

  .close-button {
    width: 32px;
    position: absolute;
    top: 45px;
    right: 16px;
  }
  /* footer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

  } */
}
